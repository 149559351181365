@font-face {
  font-family: 'Montserrat Medium';
  src: url(./fonts/Montserrat-Medium.otf); }

@font-face {
  font-family: 'Montserrat Light';
  src: url(./fonts/Montserrat-Light.otf); }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  max-width: 100vw;
  margin: 0 auto;
  scroll-behavior: smooth; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #777; }

h1 {
  font-size: 2.3rem;
  letter-spacing: 0.5px;
  width: 100%;
  font-family: "Montserrat Medium";
  line-height: 1.2;
  font-weight: 1000;
  -webkit-background-clip: text;
  color: transparent; }

section {
  width: 100%;
  display: flex;
  text-align: center; }

.skew-left {
  -webkit-transform: skew(0, -3deg) translateY(calc(50vw / -12));
  -ms-transform: skew(0, -3deg) translateY(calc(50vw / -12));
  transform: skew(0, -3deg) translateY(calc(50vw / -12)); }

.skew-right {
  -webkit-transform: skew(0, 3deg) translateY(12vh);
  -ms-transform: skew(0, 3deg) translateY(12vh);
  transform: skew(0, 3deg) translateY(12vh); }

.flexbox-row {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.gradient-line,
.gradient-line-light {
  width: 80%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 25px;
  display: block;
  border: none;
  height: 1px;
  background: #5b5e5f;
  background: linear-gradient(to right, white, #5b5e5f, #a1a9ad, #5b5e5f, white); }
  .gradient-line-light,
  .gradient-line-light-light {
    background: linear-gradient(to top left, white, #5b5e5f, #a1a9ad, #5b5e5f, white);
    width: 60%; }

.header--main {
  width: 100%;
  height: calc(95vh - 50px);
  position: relative;
  display: flex;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.7);
  background-position: 0 0;
  background-size: 110vw auto;
  animation: rotateView 30s linear infinite;
  z-index: 1; }
  @media (orientation: portrait) {
    .header--main {
      background-size: auto 110vh; } }

@keyframes rotateView {
  0% {
    background-position: 0 0; }
  48% {
    background-position: -15vmin -15vmin; }
  50% {
    background-position: -15vmin -15vmin; }
  98% {
    background-position: 0 0; }
  100% {
    background-position: 0 0; } }

.header--img-1 {
  background-image: url("../img/bg1.jpg"); }

.header--img-2 {
  background-image: url("../img/bg2.jpg"); }

.header--img-3 {
  background-image: url("../img/bg3.jpg"); }

.header--img-4 {
  background-image: url("../img/bg4.jpg"); }

.header--img-5 {
  background-image: url("../img/bg5.jpg"); }

.header--img-6 {
  background-image: url("../img/bg6.jpg"); }

.header--img-7 {
  background-image: url("../img/bg7.jpg"); }

.header--img-8 {
  background-image: url("../img/bg8.jpg"); }

.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(135deg, #777, #202020);
  background-size: 200% auto;
  transition: 1s linear; }
  .navbar a {
    color: #fff !important;
    text-decoration: none !important; }
  .navbar:hover {
    background-position: right center; }
  .navbar:active {
    transition: background-size 1.5s; }
  .navbar:hover > .navbar-brand > .nav-chevron {
    color: #d0c9c9; }
  .navbar .navbar-brand {
    padding-left: 10px;
    font-size: 1.7rem;
    letter-spacing: 1px; }
    .navbar .navbar-brand .nav-text {
      letter-spacing: 1.5px; }
      .navbar .navbar-brand .nav-text-1 {
        color: #fff;
        padding-left: 1px; }
      .navbar .navbar-brand .nav-text-2 {
        color: #d0c9c9;
        padding-right: 1px; }
    .navbar .navbar-brand .nav-chevron {
      transition: 1s linear;
      color: #000; }
  .navbar-toggler {
    background-image: linear-gradient(to right, #a7a3a3, #777);
    margin-right: 10px;
    transition: 1s; }
    .navbar-toggler:active, .navbar-toggler:focus {
      background-image: linear-gradient(to bottom right, #a7a3a3, #d0c9c9); }

.navbar-nav .nav-link {
  font-size: 1.2rem;
  padding: 0 1rem 0 1rem !important;
  transition: transform 0.2s linear; }
  .navbar-nav .nav-link:hover {
    transform: translateY(-3px);
    font-weight: 1500; }
  .navbar-nav .nav-link:active {
    transform: translateY(-1px); }

.nav-iconbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .nav-iconbar a {
    transition: transform 0.2s linear; }
    .nav-iconbar a:hover {
      transform: translateY(-3px);
      font-weight: 1500; }
    .nav-iconbar a:active {
      transform: translateY(-1px); }
  @media (max-width: 600px) {
    .nav-iconbar {
      font-size: 1rem;
      margin-top: 20px; } }
  .nav-iconbar .icon-text {
    margin: 0 20px 0 5px; }

.home {
  color: #000;
  background-image: linear-gradient(to right, rgba(201, 202, 202, 0.97) 0%, rgba(255, 255, 255, 0.97) 51%, rgba(225, 226, 228, 0.97) 100%);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  border: 1.5px solid rgba(119, 119, 119, 0.7);
  padding: 20px 0 20px 0;
  width: 35vw;
  max-width: 450px;
  max-height: 415px;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 15px;
  background-size: 200% auto;
  transition: 0.5s;
  cursor: default; }
  @media (min-width: 600px) and (max-width: 1200px) and (min-height: 1000px) {
    .home {
      width: 50vw;
      max-height: 415px; } }
  @media (max-width: 600px) {
    .home {
      width: 85vw;
      max-height: 410px; } }
  .home:hover,
  .home:hover > .home--top-section > .home--main-text {
    background-position: right center; }
  .home:active,
  .home:active > .home--top-section > .home--main-text {
    animation: background-size 1.5s; }
  @media (min-width: 768px) {
    .home--top-section {
      padding: 5px 15px 15px 15px;
      border-radius: 20px;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 5px; } }
  @media (max-width: 600px) {
    .home--top-section {
      max-width: 90%;
      margin: 0 auto;
      margin-bottom: 25px; } }
  .home--main-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%; }
    @media (min-width: 1200px) {
      .home--main-flex {
        margin-bottom: 15px; } }
  .home--main-text {
    display: block;
    margin-bottom: 20px;
    letter-spacing: 2px;
    font-size: 2.5rem;
    text-transform: uppercase;
    background-image: linear-gradient(to right, rgba(32, 32, 32, 0.95), rgba(0, 0, 0, 0.95));
    background-size: 180% auto;
    transition: 0.5s; }
    .home--main-text:hover {
      background-position: right center; }
    .home--main-text:active {
      transition: background-size 1.5s; }
  .home--main-text, .home--subtitle, .home--bottom {
    border: 0; }
  .home--subtitle, .home--bottom {
    -webkit-background-clip: text;
    color: transparent;
    line-height: 1.7;
    display: block;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Montserrat Light";
    letter-spacing: 1.5px;
    background-image: linear-gradient(to top right, #000, #202020); }
  .home--subtitle {
    margin-top: -5px; }
    .home--subtitle:first-child {
      text-transform: none; }
    .home--subtitle:last-child {
      margin-top: 10px; }
  .home--bottom {
    margin-top: 10px;
    letter-spacing: 4px; }
  .home hr {
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.43);
    margin: 0 auto;
    background: linear-gradient(to bottom, rgba(244, 242, 242, 0.1), rgba(88, 88, 88, 0.1)); }

.main-menu {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-items: center;
  margin: 10px 0 15px 0; }

.main-menu--btn {
  flex: 1;
  color: #202020;
  width: 200px;
  font-size: 0.75rem;
  letter-spacing: 0.8px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-image: radial-gradient(rgba(255, 255, 255, 0.95), rgba(238, 236, 236, 0.95));
  color: #777;
  transition: 0.2s;
  background-size: 180% auto; }
  .main-menu--btn:hover {
    background-image: radial-gradient(white, rgba(255, 255, 255, 0.95));
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.6);
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    font-weight: 1500; }
  .main-menu--btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .main-menu--btn::after {
    background-color: #fff; }
  .main-menu--btn:link, .main-menu--btn-visited {
    text-decoration: none;
    padding: 3px 8px;
    border-radius: 100px;
    display: inline-block;
    position: relative; }
  .main-menu--btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #fff;
    transition: all 0.4s; }
  .main-menu--btn:hover::after {
    transform: scaleX(1.4) scaleY(1.4);
    opacity: 0; }
  .main-menu--btn:first-child {
    margin-top: 10px; }
  .main-menu--btn:not(:first-child) {
    margin-top: 15px; }
  .main-menu--btn:last-child {
    margin-bottom: 2px; }

.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  width: 100%;
  background-color: #f3f3f3; }
  .about--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 2px;
    color: #202020;
    font-size: 1.2rem;
    text-align: center;
    width: 80%; }
    .about--content h1 {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.95), rgba(32, 32, 32, 0.95));
      background-size: 180% auto;
      transition: 0.5s; }
      .about--content h1:hover {
        background-position: right center; }
      .about--content h1:active {
        transition: background-size 1.5s; }
    .about--content-flex {
      margin-top: -25px; }
      @media (min-width: 768px) {
        .about--content-flex {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding: 20px; } }
    .about--content-img {
      flex: 1; }
      .about--content-img img {
        max-width: 240px;
        clip-path: circle(50% at 50% 50%);
        margin-top: 30px; }
      @media (max-width: 600px) {
        .about--content-img {
          margin-top: 10px; } }
    .about--content-text {
      flex: 3;
      font-size: 1rem;
      text-align: left;
      padding: 20px 10px 10px 30px; }
  .about--separator {
    height: 80px;
    width: 100vw;
    background-image: linear-gradient(135deg, #cac8c8, #d0c9c9);
    top: 0;
    z-index: -1;
    -webkit-transform: skew(0, -3deg) translateY(calc(50vw / -12));
    -ms-transform: skew(0, -3deg) translateY(calc(50vw / -12));
    transform: skew(0, -3deg) translateY(calc(50vw / -12)); }

.skills {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #454545;
  height: auto;
  width: 100%; }
  .skills--content {
    padding: 40px 20px;
    color: #fff;
    font-size: 1.2rem; }
    .skills--content h1 {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.95), rgba(208, 201, 201, 0.95));
      background-size: 180% auto;
      transition: 0.5s; }
      .skills--content h1:hover {
        background-position: right center; }
      .skills--content h1:active {
        transition: background-size 1.5s; }
    .skills--content-flex {
      display: flex;
      flex-direction: column;
      width: 600px;
      margin: 0 auto; }
      .skills--content-flex > .skill {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 20px; }
        @media (max-width: 600px) {
          .skills--content-flex > .skill {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 80%;
            margin: 0 auto; } }
        .skills--content-flex > .skill h3,
        .skills--content-flex > .skill svg,
        .skills--content-flex > .skill p {
          width: 30%; }
          @media (max-width: 600px) {
            .skills--content-flex > .skill h3,
            .skills--content-flex > .skill svg,
            .skills--content-flex > .skill p {
              width: 90%; } }
        .skills--content-flex > .skill h3 {
          text-align: center;
          padding: 5px; }
        .skills--content-flex > .skill svg {
          margin: 15px; }
          .skills--content-flex > .skill svg:first-child {
            transform: translateX(10px); }
          @media (max-width: 600px) {
            .skills--content-flex > .skill svg {
              margin: 5px; } }
        .skills--content-flex > .skill p {
          margin-top: 15px;
          font-size: 1rem;
          white-space: nowrap; }
          @media (max-width: 600px) {
            .skills--content-flex > .skill p {
              width: 85%; } }
        .skills--content-flex > .skill .skill-columns {
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-content: space-between;
          white-space: nowrap; }
          .skills--content-flex > .skill .skill-columns p {
            width: 50%; }
            .skills--content-flex > .skill .skill-columns p:first-child {
              margin-right: 15px; }
    .skills--content-bottom {
      padding: 0 1rem;
      margin: 0 auto;
      margin-top: 20px; }
      .skills--content-bottom p {
        width: 80%;
        margin: 0 auto;
        font-size: 1rem; }
      .skills--content-bottom-flex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;
        padding: 20px 100px; }
        .skills--content-bottom-flex h4 {
          flex: 0 0 33.333333%;
          font-size: 1rem;
          padding: 1.2rem;
          font-weight: 600; }
          @media (max-width: 600px) {
            .skills--content-bottom-flex h4 {
              font-size: 0.8rem; } }

.projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f3f3f3;
  height: auto;
  width: 100%; }
  .projects--content {
    padding: 50px 2px;
    color: #202020;
    font-size: 1.2rem; }
    .projects--content h1 {
      background-image: linear-gradient(to right, rgba(69, 69, 69, 0.95), rgba(32, 32, 32, 0.95));
      background-size: 180% auto;
      transition: 0.5s; }
      .projects--content h1:hover {
        background-position: right center; }
      .projects--content h1:active {
        transition: background-size 1.5s; }
    .projects--content .project {
      background-color: #fcfcfc;
      padding: 20px 2px;
      width: 90%;
      max-width: 1000px;
      margin: 0 auto;
      margin-bottom: 35px;
      border-radius: 20px;
      border: 0.5px solid rgba(0, 0, 0, 0.1); }
      @media (max-width: 600px) {
        .projects--content .project {
          width: 95%;
          padding: 0; } }
      .projects--content .project-flex {
        display: flex;
        flex-direction: row;
        width: 90%;
        margin: 0 auto; }
        @media (max-width: 600px) {
          .projects--content .project-flex {
            flex-direction: column;
            width: 90%; } }
      .projects--content .project .project-img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
      .projects--content .project img {
        flex: 1;
        max-height: 300px;
        max-width: 300px; }
        .projects--content .project img:first-child {
          margin: 20px 20px 20px 0; }
      .projects--content .project-text {
        flex: 1;
        padding: 30px 20px 10px 20px;
        margin: 0 auto;
        font-size: 0.9rem; }
        @media (max-width: 600px) {
          .projects--content .project-text {
            padding: 30px 2px 15px 2px; } }
        .projects--content .project-text h3 {
          color: #202020;
          font-weight: 600;
          margin-bottom: 20px; }
        .projects--content .project-text p {
          text-align: left; }
        .projects--content .project-text .project-details {
          padding: 5px; }
        .projects--content .project-text .project-links {
          text-align: center; }
          @media (max-width: 600px) {
            .projects--content .project-text .project-links {
              white-space: nowrap; }
              .projects--content .project-text .project-links a {
                margin-bottom: 10px; } }

.footer {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #777, #202020);
  background-size: 200% auto;
  transition: 1s linear; }
  .footer:hover {
    background-position: right center; }
  .footer:active {
    transition: background-size 1.5s; }
  .footer,
  .footer a {
    color: #fff !important;
    text-decoration: none !important; }
  .footer--content-center {
    font-size: 1.2rem; }
    .footer--content-center .icon-text {
      margin: 0 2rem 0 0.4rem; }
      @media (max-width: 600px) {
        .footer--content-center .icon-text {
          margin: 0 1rem 0 0.2rem; } }
  .footer--content--bottom {
    font-size: 0.8rem;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
